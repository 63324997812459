@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Quicksand";
  src: url("fonts/Quicksand-VariableFont_wght.ttf");
}

html,
body,
#root {
  height: 100%;
}

* {
  font-synthesis: none !important;
}
