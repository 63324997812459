.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range:hover {
  background-color: rgba(var(--primary)) !important;
}

.react-datepicker__day--in-range:hover {
  filter: brightness(95%);
}

.react-datepicker__day--in-selecting-range {
  opacity: 50%;
}

.react-datepicker-popper {
  z-index: 1000;
}
